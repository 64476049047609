import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import AppBar from './components/AppBar'
import Footer from './components/Footer'
import { Col, Container, Row } from 'react-bootstrap'
import { Buttons } from '../components/Buttons/Buttons'
import { useNavigate } from 'react-router-dom';
import { MagicStar,HeartTick,Quant,Tag2} from 'iconsax-react';
const About = () => {
    const navigate = useNavigate();
    const handlenavigate = () => {
        navigate('/');
    }
    useEffect(() => {
        AOS.init({
            offset: 200,    // Offset (in px) from the original trigger point
            delay: 0,       // Values from 0 to 3000, with step 50ms
            duration: 4000, // Values from 0 to 3000, with step 50ms
            easing: 'ease', // Default easing for AOS animations
            mirror: true,  // Whether elements should animate out while scrolling past them
          });
      }, []);
  return (
    <>
      
        <AppBar/>
        <img src={require('../assets/images/banner_one.jpg')} className='img-fluid w-100' alt='product name' />
            <>
            <div className='padding'>
                <Container>
                    <Row>
                        <Col lg='6' className='py-3 align-self-center'>
                            <h3 className='bold'>About Us</h3>
                            <h4 className='bold'> Suguna Crackers</h4>
                            <div className='regular'>
                                <p>Welcome to Suguna Crackers, your one-stop destination for high-quality crackers available both in retail and wholesale! As one of the leading cracker stores in the region, we offer an extensive variety of fireworks ranging from sparklers, rockets, flowerpots, and more, ensuring celebrations filled with joy, color, and excitement.</p>

                                <p>Our shop caters to all types of events, from festivals like Diwali and New Year to weddings, birthdays, and special occasions. We proudly maintain the highest safety standards, ensuring that our products are not only vibrant but also reliable and safe for use.</p>

                                <p>Whether you're looking for a small pack to light up a cozy family gathering or bulk orders for large events or businesses, we provide competitive pricing and premium quality products that never fail to impress.</p>
                            </div>
                        </Col>
                        <Col lg="6" className='py-3 text-center'>
                            <img src={require('../assets/images/about.png')} className='img-fluid' alt='product'/>
                        </Col>
                    </Row>
                </Container>
            </div>
               
                <div className='counter padding bg-overlay z'>
                    <Container>
                            <Row>
                                <Col lg="12" className='py-3 text-center z'>
                                     <h4 className='bold text-white'>We have handpicked your favorite crackers in our pricelist..Please check it . . !!!</h4>

                                     <Buttons label={<>View More ... !</>} onClick={handlenavigate}/>
                                </Col>
                            </Row>
                    </Container>
                </div>
                
                <Container className='padding'>
                    <Row>
                        <Col lg="4" md="6" xs="12" className='py-3'>
                            <div className='text-center py-5' data-aos="zoom-in-right">
                                <div className='choose' data-aos="zoom-in-left">
                                <Quant
                                    size="32"
                                    color="#555555"
                                    />
                                </div>
                                    <h2 class="bold">Quality</h2>
                                    <p class="regular">Manufacturing Quality Crackers &amp; innovation are the key behind our success.</p>
                            </div>
                            <div className='text-center py-5' data-aos="zoom-in-right">
                                <div className='choose' >
                                <Tag2
                                    size="32"
                                    color="#555555"
                                    />
                                </div>
                                    <h2 class="bold">Affordeble Price</h2>
                                    <p class="regular">We are producing safe and compliant crackers with highest quality at low price.</p>
                            </div>
                        </Col>

                        <Col lg="4" md="6" xs="12" className='align-self-center'>
                            <img src={require('../assets/images/chottabeam.gif')} className='img-fluid chutti' alt='product' data-aos="zoom-in"/>
                        </Col>
                        <Col lg="4" md="6" xs="12" className='py-3'>
                            <div className='text-center py-5' data-aos="flip-left">
                                <div className='choose'>
                                 <MagicStar size="32" color="#555555"/>
                                </div>
                                    <h2 class="bold">Safe To Use</h2>
                                    <p class="regular">Crackers we offer are safe and made from fine quality raw materials.</p>
                            </div>
                            <div className='text-center py-5' data-aos="flip-right">
                                <div className='choose'>
                                <HeartTick
                                    size="32"
                                    color="#555555"
                                    />
                                </div>
                                    <h2 class="bold">Satisfaction</h2>
                                    <p class="regular">Our quality and timely delivery has attracted customers easily.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <img src={require('../assets/images/aboutbanner.jpg')} className='img-fluid w-100' alt='product'/>
            </>
        <Footer/>
    </>
  )
}

export default About